<template src="./AcademicRequest.html">

</template>

<script>

import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import coursesService from "@/services/Courses";
import {mapGetters} from "vuex";

export default {
  name: "AcademicRequest",
  components: {
    ArrobaMedellinSeeker
  },
  data() {
    return {
      tableDataMembers: [
        {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        }, {
          name: "Dani Solis Dr.",
          age: 21,
          location: "Manzanillo",
          form: "www.google.com"
        },
      ],
      tableDataCategories: [
        {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        }, {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        }, {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        }, {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        }, {
          id: "1",
          name: "Comunicación social",
          description: "Comunicación",
          image: "../../assets/img/default.jpeg",
          icon: '',
          edit: '',
        },
      ],
      searchResults: [
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
        {name: 'Este es el resultado numero uno de la busqueda'},
      ],
      infoFormsAcademic: [
        {
          name: "Nombre de curso muy largo",
          institution: 'institución',
          members: 999
        }, {
          name: "Nombre de curso muy largo",
          institution: 'institución',
          members: 999
        }, {
          name: "Nombre de curso muy largo",
          institution: 'institución',
          members: 999
        }, {
          name: "Nombre de curso muy largo",
          institution: 'institución',
          members: 999
        },
      ],
      counterTeacher: {},
      counterStudents: {},
      teacherList:[]
    }
  },
  methods: {
    async getCountUsersTeacherAndInvestigator() {
      try {
        this.counterTeacher = await coursesService.getCountUsersTeacherAndInvestigator(this.user.institutionId.id)
        console.log('getAllCoursesForm', this.counterTeacher);
      } catch (e) {
        console.log('e', e);
      }
    },
    async getCountStudentsByInstitution() {
      this.counterStudents = await coursesService.getCountStudentsByInstitution(this.user.institutionId.id, 'Student')
    },
    async getTeacherList() {
      this.teacherList = await coursesService.getCountStudentsByRoleAndInstitution(this.user.institutionId.id, this.user.role.id)
      console.log("this.teacherList", this.teacherList)
    }
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  mounted() {
    this.getCountUsersTeacherAndInvestigator();
    this.getCountStudentsByRoleAndInstitution();
    this.getTeacherList();
  }

}
</script>

<style scoped lang="scss" src="./AcademicRequest.scss">

</style>
